/* IMPORTS */

// SCSS import:
import './Portfolio.scss';
import './PortfolioQuery.scss';
// Images:
import LaRosa from '../../../Assets/Images/La Rosa.jpg';
import TheColors from '../../../Assets/Images/The Color.jpeg';
import TropicalLove1 from '../../../Assets/Images/Tropical Love 1.jpg';
import LaBlanca from '../../../Assets/Images/La Blanca.jpeg';
import Monstera from '../../../Assets/Images/Monstera.jpg';
import Carnation from '../../../Assets/Images/Carnation.jpg';
import CalasBlancas from '../../../Assets/Images/Calas Blancas.jpg';
import Manzanas from '../../../Assets/Images/Manzanas.jpg';
import Crystal from '../../../Assets/Images/Crystal.jpg';
import ChicaEspalda from '../../../Assets/Images/Chica de Espalda.jpg';
import TropicalLove2 from '../../../Assets/Images/Tropical Love 2.jpg';
import Tropical from '../../../Assets/Images/Tropical.jpg';
import WhiteHibiscus from '../../../Assets/Images/White Hibiscus.jpg';
import Consciencia from '../../../Assets/Images/Consciencia del Color.jpg';
import Naranja from '../../../Assets/Images/Naranja en Flor.jpg';
import SweetHome from '../../../Assets/Images/Sweet Home.jpg';
import Orquidea from '../../../Assets/Images/Orquidea Tropical.jpeg';
import Splash from '../../../Assets/Images/Splash.jpg';
import TropicalLove3 from '../../../Assets/Images/Tropical Love.jpg';
import Teddy from '../../../Assets/Images/Teddy Bears.jpg';
import Sunflowers from '../../../Assets/Images/Sunflowers.jpg';
import Tulip from '../../../Assets/Images/Tulip.jpg';
import Martina from '../../../Assets/Images/Martina.jpg';
import LovePink from '../../../Assets/Images/Love Pink.jpg';
import Tangerines from '../../../Assets/Images/Tangerines.jpg';
import Infinity from '../../../Assets/Images/Infinity.jpeg';
import Infinito from '../../../Assets/Images/Infinito.jpg';
import Amapolas from '../../../Assets/Images/Amapolas.jpg';
import AzulProfundo from '../../../Assets/Images/Azul Profundo.jpg';
import Salute from '../../../Assets/Images/Salute.jpg';
import Home from '../../../Assets/Images/Home.jpg';
import Petunia from '../../../Assets/Images/Petunia.jpg';
import Santa from '../../../Assets/Images/Santa.jpg';
import Cielo from '../../../Assets/Images/Cielo.jpg';
import Primarios from '../../../Assets/Images/Primarios.jpg';
import Membrillos from "../../../Assets/Images/Membrillos.jpg";
import Pineapples from '../../../Assets/Images/Tropical Pineapples.jpg';
import Mickey from '../../../Assets/RecentlyImages/Mickey Mouse.jpg';
import Ella from '../../../Assets/RecentlyImages/Ella.jpg';
import ElPintor from '../../../Assets/RecentlyImages/El Pintor.jpg';
import Caracol from '../../../Assets/RecentlyImages/Caracol.PNG';



// React import:
import React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';



/* COMPONENTS */
// GalleryImg component:
function Portfolio() {
    const [t] = useTranslation("global");

    return (
        <Container fluid className='GalleryImgContainer' id='portfolio'>
            <div>
                <h3 className='PortfolioTitle'> {t('portfolio.title')}.</h3>

                <div className="GalleryWrapper">

                    <div className="ImageWrapper ImageWrapper1 Big">
                        <a href="#lightbox-TropicalLove1">
                            <img className='GridItem One' src={TropicalLove1} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Tropical Love 1 </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper2">
                        <a href="#lightbox-TheColors">
                            <img className='GridItem Two' src={TheColors} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> The Colors </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper3">
                        <a href="#lightbox-LaRosa">
                            <img className='GridItem' src={LaRosa} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> La Rosa </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper4">
                        <a href="#lightbox-LaBlanca">
                            <img className='GridItem' src={LaBlanca} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> La Blanca </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper5">
                        <a href="#lightbox-Monstera">
                            <img className='GridItem Five' src={Monstera} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Monstera </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper6">
                        <a href="#lightbox-Carnation">
                            <img className='GridItem Six' src={Carnation} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Carnation </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper7">
                        <a href="#lightbox-CalasBlancas">
                            <img className='GridItem Seven' src={CalasBlancas} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Calas Blancas </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper8 Big">
                        <a href="#lightbox-Manzanas">
                            <img className='GridItem Eight' src={Manzanas} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Manzanas </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper9">
                        <a href="#lightbox-Crystal">
                            <img className='GridItem' src={Crystal} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Crystal </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper10">
                        <a href="#lightbox-ChicaEspalda">
                            <img className='GridItem' src={ChicaEspalda} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Chica de Espalda </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper11">
                        <a href="#lightbox-TropicalLove2">
                            <img className='GridItem' src={TropicalLove2} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Tropical Love 2 </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper12">
                        <a href="#lightbox-Tropical">
                            <img className='GridItem Twelve' src={Tropical} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Tropical </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper13">
                        <a href="#lightbox-WhiteHibiscus">
                            <img className='GridItem Twelve' src={WhiteHibiscus} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> White Hibiscus </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper14">
                        <a href="#lightbox-ConscienciaDelColor">
                            <img className='GridItem Thirteen' src={Consciencia} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Consciencia en Colores </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper15 Wide">
                        <a href="#lightbox-Naranja">
                            <img className='GridItem Thirteen' src={Naranja} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Naranja en Flor </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper16">
                        <a href="#lightbox-SweetHome">
                            <img className='GridItem Thirteen' src={SweetHome} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Sweet Home </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper17">
                        <a href="#lightbox-Orquidea">
                            <img className='GridItem Thirteen' src={Orquidea} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Orquidea Tropical </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper18">
                        <a href="#lightbox-Splash">
                            <img className='GridItem Thirteen' src={Splash} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Splash </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper19">
                        <a href="#lightbox-TropicalLove3">
                            <img className='GridItem Thirteen' src={TropicalLove3} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Tropical Love 3 </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper20">
                        <a href="#lightbox-Teddy">
                            <img className='GridItem Thirteen' src={Teddy} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Teddy Bears </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper21 Big">
                        <a href="#lightbox-Sunflowers">
                            <img className='GridItem Thirteen' src={Sunflowers} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Sunflowers </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper22">
                        <a href="#lightbox-Tulip">
                            <img className='GridItem Thirteen' src={Tulip} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Tulip </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper23">
                        <a href="#lightbox-Martina">
                            <img className='GridItem Thirteen' src={Martina} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Martina </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper24">
                        <a href="#lightbox-LovePink">
                            <img className='GridItem Thirteen' src={LovePink} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Love Pink </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper25">
                        <a href="#lightbox-Tangerines">
                            <img className='GridItem Thirteen' src={Tangerines} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Tangerines </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper26">
                        <a href="#lightbox-Infinity">
                            <img className='GridItem Thirteen' src={Infinity} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Infinity </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper27">
                        <a href="#lightbox-Infinito">
                            <img className='GridItem Thirteen' src={Infinito} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Infinito </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper28">
                        <a href="#lightbox-Amapolas">
                            <img className='GridItem Thirteen' src={Amapolas} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Amapolas </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper29">
                        <a href="#lightbox-AzulProfundo">
                            <img className='GridItem Thirteen' src={AzulProfundo} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Azul Profundo </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper30">
                        <a href="#lightbox-Salute">
                            <img className='GridItem Thirteen' src={Salute} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Salute </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper31">
                        <a href="#lightbox-Home">
                            <img className='GridItem Thirteen' src={Home} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Home </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper32">
                        <a href="#lightbox-Petunia">
                            <img className='GridItem Thirteen' src={Petunia} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Petunia </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper33 Big">
                        <a href="#lightbox-Santa">
                            <img className='GridItem Thirteen' src={Santa} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Santa </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper34">
                        <a href="#lightbox-Cielo">
                            <img className='GridItem Thirteen' src={Cielo} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Cielo </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper35">
                        <a href="#lightbox-Primarios">
                            <img className='GridItem Thirteen' src={Primarios} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Primarios </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper36">
                        <a href="#lightbox-Membrillos">
                            <img className='GridItem Thirteen' src={Membrillos} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Membrillos </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper37">
                        <a href="#lightbox-Pineapples">
                            <img className='GridItem Thirteen' src={Pineapples} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Tropical Pineapples </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper38">
                        <a href="#lightbox-ElPintor">
                            <img className='GridItem One' src={ElPintor} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> El Pintor </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper39">
                        <a href="#lightbox-Caracol">
                            <img className='GridItem Two' src={Caracol} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> The Shell </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper40">
                        <a href="#lightbox-Mickey">
                            <img className='GridItem' src={Mickey} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Mickey Mouse </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper41">
                        <a href="#lightbox-Ella">
                            <img className='GridItem' src={Ella} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Ella </p>
                        </div >
                    </div>

                </div>


            {/* LIGHTBOXES */}
                <div className="gallery-lightboxes">
                    <div className="image-lightbox" id="lightbox-TropicalLove1">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Ella" className="arrow-left"></a>
                            <a href="#lightbox-TheColors" className="arrow-right"></a>
                            <img src={TropicalLove1} alt="" />
                            <div className='ImgDescription'>
                                <p> Tropical Love 1 - {t('portfolio.TropicalLove1')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-TheColors">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-TropicalLove1" className="arrow-left"></a>
                            <a href="#lightbox-LaRosa" className="arrow-right"></a>
                            <img src={TheColors} alt="" />
                            <div className='ImgDescription'>
                                <p> The Colors - {t('portfolio.TheColors')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-LaRosa">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-TheColors" className="arrow-left"></a>
                            <a href="#lightbox-LaBlanca" className="arrow-right"></a>
                            <img src={LaRosa} alt="" />
                            <div className='ImgDescription'>
                                <p> La Rosa - {t('portfolio.LaRosa')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-LaBlanca">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-LaRosa" className="arrow-left"></a>
                            <a href="#lightbox-Monstera" className="arrow-right"></a>
                            <img src={LaBlanca} alt="" />
                            <div className='ImgDescription'>
                                <p> La Blanca - {t('portfolio.LaBlanca')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Monstera">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-LaBlanca" className="arrow-left"></a>
                            <a href="#lightbox-Carnation" className="arrow-right"></a>
                            <img src={Monstera} alt="" />
                            <div className='ImgDescription'>
                                <p> Monstera - {t('portfolio.Monstera')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Carnation">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Monstera" className="arrow-left"></a>
                            <a href="#lightbox-CalasBlancas" className="arrow-right"></a>
                            <img src={Carnation} alt="" />
                            <div className='ImgDescription'>
                                <p> Carnation - {t('portfolio.Carnation')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-CalasBlancas">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Carnation" className="arrow-left"></a>
                            <a href="#lightbox-Manzanas" className="arrow-right"></a>
                            <img src={CalasBlancas} alt="" />
                            <div className='ImgDescription'>
                                <p> Calas Blancas - {t('portfolio.CalasBlancas')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Manzanas">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-CalasBlancas" className="arrow-left"></a>
                            <a href="#lightbox-Crystal" className="arrow-right"></a>
                            <img src={Manzanas} alt="" />
                            <div className='ImgDescription'>
                                <p> Manzanas - {t('portfolio.Manzanas')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Crystal">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Manzanas" className="arrow-left"></a>
                            <a href="#lightbox-ChicaEspalda" className="arrow-right"></a>
                            <img src={Crystal} alt="" />
                            <div className='ImgDescription'>
                                <p> Crystal - {t('portfolio.Crystal')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-ChicaEspalda">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Crystal" className="arrow-left"></a>
                            <a href="#lightbox-TropicalLove2" className="arrow-right"></a>
                            <img src={ChicaEspalda} alt="" />
                            <div className='ImgDescription'>
                                <p> Chica De Espalda - {t('portfolio.ChicaEspalda')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-TropicalLove2">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-ChicaEspalda" className="arrow-left"></a>
                            <a href="#lightbox-Tropical" className="arrow-right"></a>
                            <img src={TropicalLove2} alt="" />
                            <div className='ImgDescription'>
                                <p> Tropical Love 2 - {t('portfolio.TropicalLove2')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Tropical">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-TropicalLove2" className="arrow-left"></a>
                            <a href="#lightbox-WhiteHibiscus" className="arrow-right"></a>
                            <img src={Tropical} alt="" />
                            <div className='ImgDescription'>
                                <p> Tropical - {t('portfolio.Tropical')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-WhiteHibiscus">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Tropical" className="arrow-left"></a>
                            <a href="#lightbox-ConscienciaDelColor" className="arrow-right"></a>
                            <img src={WhiteHibiscus} alt="" />
                            <div className='ImgDescription'>
                                <p> White Hibiscus - {t('portfolio.WhiteHibiscus')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-ConscienciaDelColor">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-WhiteHibiscus" className="arrow-left"></a>
                            <a href="#lightbox-Naranja" className="arrow-right"></a>
                            <img src={Consciencia} alt="" />
                            <div className='ImgDescription'>
                                <p> Consciencia en Colores - {t('portfolio.Consciencia')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Naranja">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-ConscienciaDelColor" className="arrow-left"></a>
                            <a href="#lightbox-SweetHome" className="arrow-right"></a>
                            <img src={Naranja} alt="" />
                            <div className='ImgDescription'>
                                <p> Naranja en Flor - {t('portfolio.Naranja')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-SweetHome">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Naranja" className="arrow-left"></a>
                            <a href="#lightbox-Orquidea" className="arrow-right"></a>
                            <img src={SweetHome} alt="" />
                            <div className='ImgDescription'>
                                <p> Sweet Home - {t('portfolio.SweetHome')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Orquidea">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-SweetHome" className="arrow-left"></a>
                            <a href="#lightbox-Splash" className="arrow-right"></a>
                            <img src={Orquidea} alt="" />
                            <div className='ImgDescription'>
                                <p> Orquídea Tropical - {t('portfolio.Orquidea')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Splash">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Orquidea" className="arrow-left"></a>
                            <a href="#lightbox-TropicalLove3" className="arrow-right"></a>
                            <img src={Splash} alt="" />
                            <div className='ImgDescription'>
                                <p> Splash - {t('portfolio.Splash')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-TropicalLove3">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Splash" className="arrow-left"></a>
                            <a href="#lightbox-Teddy" className="arrow-right"></a>
                            <img src={TropicalLove3} alt="" />
                            <div className='ImgDescription'>
                                <p> Tropical Love 3 - {t('portfolio.TropicalLove3')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Teddy">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-TropicalLove3" className="arrow-left"></a>
                            <a href="#lightbox-Sunflowers" className="arrow-right"></a>
                            <img src={Teddy} alt="" />
                            <div className='ImgDescription'>
                                <p> Teddy Bears - {t('portfolio.Teddy')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Sunflowers">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-image-20" className="arrow-left"></a>
                            <a href="#lightbox-Tulip" className="arrow-right"></a>
                            <img src={Sunflowers} alt="" />
                            <div className='ImgDescription'>
                                <p> Sunflowers - {t('portfolio.Sunflowers')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Tulip">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Sunflowers" className="arrow-left"></a>
                            <a href="#lightbox-Martina" className="arrow-right"></a>
                            <img src={Tulip} alt="" />
                            <div className='ImgDescription'>
                                <p> Tulip - {t('portfolio.Tulip')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Martina">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Tulip" className="arrow-left"></a>
                            <a href="#lightbox-LovePink" className="arrow-right"></a>
                            <img src={Martina} alt="" />
                            <div className='ImgDescription'>
                                <p> Martina - {t('portfolio.Martina')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-LovePink">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Martina" className="arrow-left"></a>
                            <a href="#lightbox-Tangerines" className="arrow-right"></a>
                            <img src={LovePink} alt="" />
                            <div className='ImgDescription'>
                                <p> Love Pink - {t('portfolio.LovePink')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Tangerines">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-LovePink" className="arrow-left"></a>
                            <a href="#lightbox-Infinity" className="arrow-right"></a>
                            <img src={Tangerines} alt="" />
                            <div className='ImgDescription'>
                                <p> Tangerines - {t('portfolio.Tangerines')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Infinity">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Tangerines" className="arrow-left"></a>
                            <a href="#lightbox-Infinito" className="arrow-right"></a>
                            <img src={Infinity} alt="" />
                            <div className='ImgDescription'>
                                <p> Infinity - {t('portfolio.Infinity')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Infinito">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Infinity" className="arrow-left"></a>
                            <a href="#lightbox-Amapolas" className="arrow-right"></a>
                            <img src={Infinito} alt="" />
                            <div className='ImgDescription'>
                                <p> Infinito - {t('portfolio.Infinito')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Amapolas">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Infinito" className="arrow-left"></a>
                            <a href="#lightbox-AzulProfundo" className="arrow-right"></a>
                            <img src={Amapolas} alt="" />
                            <div className='ImgDescription'>
                                <p> Amapolas - {t('portfolio.Amapolas')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-AzulProfundo">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Amapolas" className="arrow-left"></a>
                            <a href="#lightbox-Salute" className="arrow-right"></a>
                            <img src={AzulProfundo} alt="" />
                            <div className='ImgDescription'>
                                <p> Azul Profundo - {t('portfolio.AzulProfundo')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Salute">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-AzulProfundo" className="arrow-left"></a>
                            <a href="#lightbox-Home" className="arrow-right"></a>
                            <img src={Salute} alt="" />
                            <div className='ImgDescription'>
                                <p> Salute - {t('portfolio.Salute')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Home">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Salute" className="arrow-left"></a>
                            <a href="#lightbox-Petunia" className="arrow-right"></a>
                            <img src={Home} alt="" />
                            <div className='ImgDescription'>
                                <p> Home - {t('portfolio.Home')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Petunia">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Home" className="arrow-left"></a>
                            <a href="#lightbox-Santa" className="arrow-right"></a>
                            <img src={Petunia} alt="" />
                            <div className='ImgDescription'>
                                <p> Petunia - {t('portfolio.Petunia')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Santa">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Petunia" className="arrow-left"></a>
                            <a href="#lightbox-Petunia" className="arrow-right"></a>
                            <img src={Santa} alt="" />
                            <div className='ImgDescription'>
                                <p> Santa - {t('portfolio.Santa')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Cielo">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Santa" className="arrow-left"></a>
                            <a href="#lightbox-Primarios" className="arrow-right"></a>
                            <img src={Cielo} alt="" />
                            <div className='ImgDescription'>
                                <p> Cielo - {t('portfolio.Cielo')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Primarios">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Cielo" className="arrow-left"></a>
                            <a href="#lightbox-Membrillos" className="arrow-right"></a>
                            <img src={Primarios} alt="" />
                            <div className='ImgDescription'>
                                <p> Primarios - {t('portfolio.Primarios')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Membrillos">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Primarios" className="arrow-left"></a>
                            <a href="#lightbox-Pineapples" className="arrow-right"></a>
                            <img src={Membrillos} alt="" />
                            <div className='ImgDescription'>
                            <p> Membrillos - {t('portfolio.Membrillos')} </p>
                        </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Pineapples">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Membrillos" className="arrow-left"></a>
                            <a href="#lightbox-ElPintor" className="arrow-right"></a>
                            <img src={Pineapples} alt="" />
                            <div className='ImgDescription'>
                                <p> Tropical Pineapples - {t('portfolio.Pineapples')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-ElPintor">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Pineapples" className="arrow-left"></a>
                            <a href="#lightbox-Caracol" className="arrow-right"></a>
                            <img src={ElPintor} alt="" />
                            <div className='ImgDescription'>
                                <p> El Pintor - {t('portfolio.ElPintor')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Caracol">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-ElPintor" className="arrow-left"></a>
                            <a href="#lightbox-Mickey" className="arrow-right"></a>
                            <img src={Caracol} alt="" />
                            <div className='ImgDescription'>
                                <p> The Shell - {t('portfolio.Caracol')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Mickey">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Caracol" className="arrow-left"></a>
                            <a href="#lightbox-Ella" className="arrow-right"></a>
                            <img src={Mickey} alt="" />
                            <div className='ImgDescription'>
                                <p> Mickey Mouse - {t('portfolio.Mickey')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Ella">
                        <div className="image-lightbox-wrapper">
                            <a href="#gallery" className="close"></a>
                            <a href="#lightbox-Mickey" className="arrow-left"></a>
                            <a href="#lightbox-TropicalLove1" className="arrow-right"></a>
                            <img src={Ella} alt="" />
                            <div className='ImgDescription'>
                                <p> Ella - {t('portfolio.Ella')} </p>
                            </div >
                        </div>
                    </div>

                </div>
            </div>
        </Container>
    );
}

export default Portfolio;