/* IMPORTS */

// SCSS import:
import './RecentlyImages.scss';
import './RecentlyImagesQuery.scss';
// Images:
import BlueHibiscus from '../../../Assets/RecentlyImages/BlueHibiscus.jpg'
import NaturalLove from '../../../Assets/RecentlyImages/Natural Love.jpg';
import Limones from '../../../Assets/RecentlyImages/Limones.jpg';
import Rosemary from '../../../Assets/RecentlyImages/Rosemary.jpg';
import Inspiracion from '../../../Assets/RecentlyImages/Inspiracion.jpg';
import Silencio from '../../../Assets/RecentlyImages/El Silencio.jpg';
import Flamenco from '../../../Assets/RecentlyImages/Flamenco.jpg';
import Racimo from '../../../Assets/RecentlyImages/El Racimo.jpg';
import Hibiscus from '../../../Assets/RecentlyImages/Hibiscus.jpg';
import Multifloral from '../../../Assets/RecentlyImages/Multifloral.jpg';
import Rafaela from '../../../Assets/RecentlyImages/Rafaela.jpg';
import Life from '../../../Assets/RecentlyImages/Life.jpg';
import TropicalConversation from '../../../Assets/RecentlyImages/Tropical Conversation.jpg';
import BesoAzul from '../../../Assets/Images/Beso Azul.jpg';
import BlueKiss from '../../../Assets/Images/Blue Kiss.jpg';
import MyStar from '../../../Assets/RecentlyImages/MyStar.jpg';
import HawaiianLovePlumeria from '../../../Assets/RecentlyImages/Hawaiian.jpg';
import SeaCalling from '../../../Assets/RecentlyImages/SeaCalling.jpg';
import Tortuga from '../../../Assets/RecentlyImages/Tortuga.jpg';
import GoldShell from '../../../Assets/RecentlyImages/GoldShell.jpg';

// import ElPintor from '../../../Assets/RecentlyImages/El Pintor.jpg';
// import Caracol from '../../../Assets/RecentlyImages/Caracol.PNG';
// import Mickey from '../../../Assets/RecentlyImages/Mickey Mouse.jpg';
// import Ella from '../../../Assets/RecentlyImages/Ella.jpg';

// React import:
import React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';



/* COMPONENTS */
// RecentlyImages component:
function RecentlyImages() {
    const [t] = useTranslation("global");

    return (
        <Container fluid className='GalleryImgContainer' id='recently'>
            <div>
                <h3 className='PortfolioTitle'> {t('recently.title')} </h3>

                <div className="GalleryWrapperRecently">

                    {/* <div className="ImageWrapper ImageWrapper1">
                        <a href="#lightbox-ElPintor">
                            <img className='GridItem One' src={ElPintor} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> El Pintor </p>
                        </div >
                    </div> */}

                    {/* <div className="ImageWrapper ImageWrapper2">
                        <a href="#lightbox-Caracol">
                            <img className='GridItem Two' src={Caracol} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> The Shell </p>
                        </div >
                    </div> */}

                    <div className="ImageWrapper ImageWrapper1">
                        <a href="#lightbox-GoldShell">
                            <img className='GridItem' src={GoldShell} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Caracol Dorado </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper2">
                        <a href="#lightbox-NaturalLove">
                            <img className='GridItem' src={NaturalLove} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Natural Love </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper3">
                        <a href="#lightbox-Limones">
                            <img className='GridItem' src={Limones} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Limones </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper4">
                        <a href="#lightbox-Rosemary">
                            <img className='GridItem Five' src={Rosemary} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Rosemary </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper5">
                        <a href="#lightbox-Inspiracion">
                            <img className='GridItem Six' src={Inspiracion} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Inspiración </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper6">
                        <a href="#lightbox-Silencio">
                            <img className='GridItem Seven' src={Silencio} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> El Silencio </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper7">
                        <a href="#lightbox-Flamenco">
                            <img className='GridItem Eight' src={Flamenco} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Flamenco </p>
                        </div >
                    </div>

                    {/* <div className="ImageWrapper ImageWrapper9">
                        <a href="#lightbox-Mickey">
                            <img className='GridItem' src={Mickey} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Mickey Mouse </p>
                        </div >
                    </div> */}

                    {/* <div className="ImageWrapper ImageWrapper10">
                        <a href="#lightbox-Ella">
                            <img className='GridItem' src={Ella} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Ella </p>
                        </div >
                    </div> */}

                    <div className="ImageWrapper ImageWrapper8">
                        <a href="#lightbox-Racimo">
                            <img className='GridItem' src={Racimo} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> El Racimo </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper9">
                        <a href="#lightbox-Hibiscus">
                            <img className='GridItem Twelve' src={Hibiscus} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Hibiscus </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper10">
                        <a href="#lightbox-Multifloral">
                            <img className='GridItem Twelve' src={Multifloral} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Multifloral </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper11">
                        <a href="#lightbox-Rafaela">
                            <img className='GridItem Thirteen' src={Rafaela} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Rafaela </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper12">
                        <a href="#lightbox-Life">
                            <img className='GridItem Thirteen' src={Life} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Life </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper13">
                        <a href="#lightbox-TropicalConversation">
                            <img className='GridItem Thirteen' src={TropicalConversation} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Tropical Conversation </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper14">
                        <a href="#lightbox-BlueHibiscus">
                            <img className='GridItem Fourteen' src={BlueHibiscus} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Blue Hibiscus </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper15">
                        <a href="#lightbox-BlueKiss">
                            <img className='GridItem Thirteen' src={BlueKiss} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Blue Kiss </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper16">
                        <a href="#lightbox-BesoAzul">
                            <img className='GridItem Thirteen' src={BesoAzul} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Beso Azul </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper17">
                        <a href="#lightbox-MyStar">
                            <img className='GridItem Thirteen' src={MyStar} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> My Star </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper18">
                        <a href="#lightbox-HawaiianLovePlumeria">
                            <img className='GridItem Thirteen' src={HawaiianLovePlumeria} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Hawaiian Lover Plumeria </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper19">
                        <a href="#lightbox-SeaCalling">
                            <img className='GridItem Thirteen' src={SeaCalling} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> The Sea Is Calling </p>
                        </div >
                    </div>

                    <div className="ImageWrapper ImageWrapper20">
                        <a href="#lightbox-Tortuga">
                            <img className='GridItem Thirteen' src={Tortuga} alt="" />
                        </a>
                        <div className='CardItemDescription'>
                            <p> Tortuga </p>
                        </div >
                    </div>

                </div>


            {/* LIGHTBOXES */}
                <div className="gallery-lightboxes">

                    {/* <div className="image-lightbox" id="lightbox-ElPintor">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-MyStar" className="arrow-left"></a>
                            <a href="#lightbox-Caracol" className="arrow-right"></a>
                            <img src={ElPintor} alt="" />
                            <div className='ImgDescription'>
                                <p> El Pintor - {t('recently.ElPintor')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Caracol">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-ElPintor" className="arrow-left"></a>
                            <a href="#lightbox-NaturalLove" className="arrow-right"></a>
                            <img src={Caracol} alt="" />
                            <div className='ImgDescription'>
                                <p> The Shell - {t('recently.Caracol')} </p>
                            </div >
                        </div>
                    </div> */}

                    <div className="image-lightbox" id="lightbox-GoldShell">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Tortuga" className="arrow-left"></a>
                            <a href="#lightbox-NaturalLove" className="arrow-right"></a>
                            <img src={GoldShell} alt="" />
                            <div className='ImgDescription'>
                                <p> Caracol Dorado - {t('recently.GoldShell')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-NaturalLove">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-GoldShell" className="arrow-left"></a>
                            <a href="#lightbox-Limones" className="arrow-right"></a>
                            <img src={NaturalLove} alt="" />
                            <div className='ImgDescription'>
                                <p> Natural Love - {t('recently.NaturalLove')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Limones">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-NaturalLove" className="arrow-left"></a>
                            <a href="#lightbox-Rosemary" className="arrow-right"></a>
                            <img src={Limones} alt="" />
                            <div className='ImgDescription'>
                                <p> Limones - {t('recently.Limones')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Rosemary">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Limones" className="arrow-left"></a>
                            <a href="#lightbox-Inspiracion" className="arrow-right"></a>
                            <img src={Rosemary} alt="" />
                            <div className='ImgDescription'>
                                <p> Rosemary - {t('recently.Rosemary')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Inspiracion">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Rosemary" className="arrow-left"></a>
                            <a href="#lightbox-Silencio" className="arrow-right"></a>
                            <img src={Inspiracion} alt="" />
                            <div className='ImgDescription'>
                                <p> Inspiración - {t('recently.Inspiracion')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Silencio">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Inspiracion" className="arrow-left"></a>
                            <a href="#lightbox-Flamenco" className="arrow-right"></a>
                            <img src={Silencio} alt="" />
                            <div className='ImgDescription'>
                                <p> El Silencio- {t('recently.Silencio')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Flamenco">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Silencio" className="arrow-left"></a>
                            <a href="#lightbox-Mickey" className="arrow-right"></a>
                            <img src={Flamenco} alt="" />
                            <div className='ImgDescription'>
                                <p> Flamenco - {t('recently.Flamenco')} </p>
                            </div >
                        </div>
                    </div>

                    {/* <div className="image-lightbox" id="lightbox-Mickey">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Flamenco" className="arrow-left"></a>
                            <a href="#lightbox-Ella" className="arrow-right"></a>
                            <img src={Mickey} alt="" />
                            <div className='ImgDescription'>
                                <p> Mickey Mouse - {t('recently.Mickey')} </p>
                            </div >
                        </div>
                    </div> */}

                    {/* <div className="image-lightbox" id="lightbox-Ella">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Mickey" className="arrow-left"></a>
                            <a href="#lightbox-Racimo" className="arrow-right"></a>
                            <img src={Ella} alt="" />
                            <div className='ImgDescription'>
                                <p> Ella - {t('recently.Ella')} </p>
                            </div >
                        </div>
                    </div> */}

                    <div className="image-lightbox" id="lightbox-Racimo">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Ella" className="arrow-left"></a>
                            <a href="#lightbox-Hibiscus" className="arrow-right"></a>
                            <img src={Racimo} alt="" />
                            <div className='ImgDescription'>
                                <p> El Racimo - {t('recently.Racimo')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Hibiscus">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Racimo" className="arrow-left"></a>
                            <a href="#lightbox-Multifloral" className="arrow-right"></a>
                            <img src={Hibiscus} alt="" />
                            <div className='ImgDescription'>
                                <p> Hibiscus - {t('recently.Hibiscus')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Multifloral">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Hibiscus" className="arrow-left"></a>
                            <a href="#lightbox-Rafaela" className="arrow-right"></a>
                            <img src={Multifloral} alt="" />
                            <div className='ImgDescription'>
                                <p> Multifloral - {t('recently.Multifloral')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Rafaela">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Multifloral" className="arrow-left"></a>
                            <a href="#lightbox-Life" className="arrow-right"></a>
                            <img src={Rafaela} alt="" />
                            <div className='ImgDescription'>
                                <p> Rafaela - {t('recently.Rafaela')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Life">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Rafaela" className="arrow-left"></a>
                            <a href="#lightbox-TropicalConversation" className="arrow-right"></a>
                            <img src={Life} alt="" />
                            <div className='ImgDescription'>
                                <p> Life - {t('recently.Life')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-TropicalConversation">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-Life" className="arrow-left"></a>
                            <a href="#lightbox-BlueHibiscus" className="arrow-right"></a>
                            <img src={TropicalConversation} alt="" />
                            <div className='ImgDescription'>
                                <p> Tropical Conversation - {t('recently.TropicalConversation')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-BlueHibiscus">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-TropicalConversation" className="arrow-left"></a>
                            <a href="#lightbox-BlueKiss" className="arrow-right"></a>
                            <img src={BlueHibiscus} alt="" />
                            <div className='ImgDescription'>
                                <p> Blue Hibiscus - {t('recently.BlueHibiscus')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-BlueKiss">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-BlueHibiscus" className="arrow-left"></a>
                            <a href="#lightbox-BesoAzul" className="arrow-right"></a>
                            <img src={BlueKiss} alt="" />
                            <div className='ImgDescription'>
                                <p> Blue Kiss - {t('recently.BlueKiss')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-BesoAzul">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-BlueKiss" className="arrow-left"></a>
                            <a href="#lightbox-MyStar" className="arrow-right"></a>
                            <img src={BesoAzul} alt="" />
                            <div className='ImgDescription'>
                                <p> Beso Azul - {t('recently.BesoAzul')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-MyStar">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-BesoAzul" className="arrow-left"></a>
                            <a href="#lightbox-HawaiianLovePlumeria" className="arrow-right"></a>
                            <img src={MyStar} alt="" />
                            <div className='ImgDescription'>
                                <p> My Star - {t('recently.MyStar')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-HawaiianLovePlumeria">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-MyStar" className="arrow-left"></a>
                            <a href="#lightbox-SeaCalling" className="arrow-right"></a>
                            <img src={HawaiianLovePlumeria} alt="" />
                            <div className='ImgDescription'>
                                <p> Hawaiian Love Plumeria - {t('recently.HawaiianLovePlumeria')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-SeaCalling">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-HawaiianLovePlumeria" className="arrow-left"></a>
                            <a href="#lightbox-Tortuga" className="arrow-right"></a>
                            <img src={SeaCalling} alt="" />
                            <div className='ImgDescription'>
                                <p> Sea Calling - {t('recently.SeaCalling')} </p>
                            </div >
                        </div>
                    </div>

                    <div className="image-lightbox" id="lightbox-Tortuga">
                        <div className="image-lightbox-wrapper">
                            <a href="#recently" className="close"></a>
                            <a href="#lightbox-SeaCalling" className="arrow-left"></a>
                            <a href="#lightbox-GoldShell" className="arrow-right"></a>
                            <img src={Tortuga} alt="" />
                            <div className='ImgDescription'>
                                <p> Tortuga - {t('recently.Tortuga')} </p>
                            </div >
                        </div>
                    </div>

                </div>
            </div>
        </Container>
    );
}

export default RecentlyImages;